import { usePeople } from "people";

export interface GroupScore {
  scores: [number];
  avg: number;
}

export type GroupScores = { [key: string]: GroupScore };

export function RankByGroupView() {
  const people = usePeople();

  const calcGroup = () => {
    let scores: GroupScores = {};
    Object.values(people).forEach((p) => {
      if (scores[p.team]) {
        scores[p.team].scores.push(p.score);
      } else {
        scores[p.team] = { scores: [p.score], avg: 0 };
      }
    });

    Object.entries(scores).forEach((s) => {
      s[1].avg = avg(s[1].scores);
    });

    return scores;
  };

  const avg = (n: [number]) => {
    return n.length > 0
      ? n.reduce((prev, curr) => {
          return prev + curr;
        }, 0) / n.length
      : 0;
  };

  const groups = calcGroup();

  return (
    <div>
      {Object.entries(groups)
        .sort((a, b) => b[1].avg - a[1].avg)
        .map((g) => (
          <div key={g[0]}>
            {g[0]} - {g[1].avg.toFixed(2)}
          </div>
        ))}
    </div>
  );
}
