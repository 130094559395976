import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Image,
  Wrap,
} from "@chakra-ui/react";
import { getFaceUrl, usePeople } from "people";
import { useEffect, useState } from "react";
import { Person } from "types";

import puck from "assets/puck.png";

interface RankedPerson extends Person {
  rank: number;
}

export function RankByPersonView() {
  const allPeople = usePeople();

  const [peopleList, setPeopleList] = useState<RankedPerson[]>([]);
  const [filterText, setFilterText] = useState("");
  const [numToShow, setNumToShow] = useState(20);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    const r = new RegExp(filterText, "i");
    const sortPeople = Object.values(allPeople)
      .sort((a, b) => b.score - a.score)
      .map((p, i) => {
        return { ...p, rank: 1 + i };
      })
      .filter((p) => p.name.match(r))
      .sort((a, b) => sortOrder * (a.rank - b.rank))
      .filter((p, i) => i < numToShow);
    setPeopleList(sortPeople);
  }, [allPeople, peopleList, numToShow, sortOrder, filterText]);

  return (
    <div>
      <Center>
        <input
          type="text"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Filter..."
        />
        <label htmlFor="numToShow">Maximum to show:</label>
        <input
          id="numToShow"
          title="Max to show"
          type="number"
          value={numToShow}
          onChange={(e) => setNumToShow(e.target.valueAsNumber)}
        />
        <select
          title="Sort order"
          value={sortOrder}
          onChange={(e) => setSortOrder(+e.target.value)}
        >
          <option value={1}>Best to worst</option>
          <option value={-1}>Worst to best</option>
        </select>
      </Center>
      <Wrap>
        {peopleList.map((p) => (
          <Card key={p.id} size="sm" m="1" align="center">
            <CardHeader>
              # {p.rank} - {p.name}
            </CardHeader>
            <CardBody>
              <Image
                src={getFaceUrl(p)}
                fallbackSrc={puck}
                alt={p.name}
                maxW={"200px"}
              />
            </CardBody>
            <CardFooter>
              {p.score} ({p.votes} votes)
            </CardFooter>
          </Card>
        ))}
      </Wrap>
    </div>
  );
}
