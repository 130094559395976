import { useEffect, useState } from "react";
import { People, Person } from "types";
import { createPlayer, getPlayerSet, getPlayers } from "dataApi";
import EloRating from "elo-rating";

import allPeople from "./people.json";

export async function loadAllPlayers() {
  // get everyone from the db
  const dbPeople = await getPlayers();

  const newPeople: People = {};

  // add anyone who wasn't there last time
  allPeople.forEach(async (p) => {
    let peep = dbPeople[p];
    if (peep) {
      peep.score = peep.score || 500;
      peep.votes = peep.votes || 0;
    } else {
      const parts = p.split("_");
      const newPeep: Person = {
        id: p,
        season: parts[0],
        team: parts[1],
        name: parts[2],
        score: 500,
        votes: 0,
      };
      console.log("Adding: " + p);
      await createPlayer(newPeep);
    }
    newPeople[p] = peep;
  });

  return newPeople;
}

export function getElligiblePlayers(people: People, minPoolSize: number = 10) {
  let elligible: Person[] = [];

  const peopleArr = Object.values(people);
  if (peopleArr.length >= minPoolSize) {
    let threshold = 1 + Math.min(...peopleArr.map((p) => p.votes));
    while (elligible.length < minPoolSize) {
      threshold++;
      elligible = peopleArr.filter((p) => p.votes <= threshold);
    }
  }
  return elligible;
}

export async function loadNextPlayerSet(
  leftId: string,
  rightId: string,
  middleId: string
): Promise<Person[]> {
  // load them both from the db
  return await getPlayerSet(leftId, rightId, middleId);
}

export function usePeople() {
  const [people, setPeople] = useState<People>({});

  useEffect(() => {
    const go = async () => {
      setPeople(await loadAllPlayers());
    };
    go();
  }, []);

  return people;
}

export function getFaceUrl(person: Person) {
  const imgSrc = "/headshots/" + person?.id?.replaceAll("_", "/") + ".png";
  return imgSrc;
}

export function updateScores(winner: Person, loser: Person) {
  const result = EloRating.calculate(winner.score, loser.score, true, 50);

  winner.score = result.playerRating;
  winner.votes += 1;

  loser.score = result.opponentRating;
  loser.votes += 1;

  console.log(`winner ${winner.name} loser ${loser.name}`);
}
