import { Person } from "types";

import puckImage from "assets/puck.png";
import { getFaceUrl } from "people";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Image,
} from "@chakra-ui/react";

export interface PersonBoxProps {
  person?: Person;
  onVote: Function;
  isOnFire?: boolean;
  showName: boolean;
}

export function PersonBox(props: PersonBoxProps) {
  const imgSrc = props.person && getFaceUrl(props.person);
  const fireText = props.isOnFire ? "🔥🔥" : "";
  const nameText = props.showName ? props.person?.name : "";

  return (
    <Center>
      <Card size="md" m={2} width="300px" minHeight="300px">
        <CardBody
          onClick={() => {
            props.onVote();
          }}
        >
          <div>
            <Image
              key={props.person?.id}
              src={imgSrc}
              fallbackSrc={puckImage}
            />
          </div>
        </CardBody>
        <CardFooter>
          <Box textAlign="left">{fireText}</Box>
          {nameText}
          <Box textAlign="right">{fireText}</Box>
        </CardFooter>
      </Card>
    </Center>
  );
}
