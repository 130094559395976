import { People, Person } from "./types";

type DBVote = {
  id: string;
  winner: string;
  winnerScore: number;
  loser: string;
  loserScore: number;
};

const dataApiUrl = "/data-api/graphql";

async function callDataApi(query: string, variables: any) {
  const request = {
    query,
    variables,
  };

  const response = await fetch(dataApiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  return response;
}

const gqlCreateVote = `
mutation CreateVote($item:CreateVoteInput!) {
    createVote(item:$item) {
        id
    }
}
`;

export async function createVote(winner: Person, loser: Person) {
  const r = Math.floor(1000000 * Math.random()).toString(16);
  const d = Date.now().toString(16);
  const newVote: DBVote = {
    id: [r, d].join("_"),
    winner: winner.id,
    winnerScore: winner.score,
    loser: loser.id,
    loserScore: loser.score,
  };

  callDataApi(gqlCreateVote, { item: newVote });
}

const gqlGetPlayers = `
query GetPlayers {
  players {
    items {
      id,
      name,
      team,
      season,
      score,
      votes
    }
  }
}
`;

export async function getPlayers() {
  const people: People = {};
  const response = await callDataApi(gqlGetPlayers, {});
  if (response.status === 200) {
    const j = await response.json();
    const players = j.data.players.items as Person[];
    players.forEach((p) => {
      const newId = p.id;
      people[newId] = {
        ...p,
        id: newId,
      };
    });
  }
  return people;
}

const gqlCreatePlayer = `
mutation CreatePlayer($item: CreatePlayerInput!) {
  createPlayer(item:$item) {
    id, name, team, season, score, votes
  }
}
`;

export async function createPlayer(person: Person) {
  // IDs can't have / \ #
  const player = {
    ...person,
    id: person.id,
  };
  await callDataApi(gqlCreatePlayer, { item: player });
}

const gqlGetPlayerPair = `
query GetPlayerPair($l:IdFilterInput!, $r:IdFilterInput!, $m:IdFilterInput!){
  players(filter:{or: [{id:$l},{id:$r},{id:$m}]}) {
    items { id, votes, score }
  }
}
`;

export async function getPlayerSet(l: string, r: string, m: string) {
  let pair: Person[] = [];
  const params = {
    l: { eq: l },
    r: { eq: r },
    m: { eq: m },
  };
  const response = await callDataApi(gqlGetPlayerPair, params);
  if (response.status === 200) {
    const j = await response.json();
    pair = j.data.players.items;
  }
  return pair;
}

const gqlUpdatePlayerScore = `
mutation UpdatePlayerScore(
  $id:ID!, 
  $partition: String!, 
  $item:UpdatePlayerInput!) {
  updatePlayer(
    id:$id, 
    _partitionKeyValue: $partition
    item:$item) {
    id
  }
}`;

export async function savePlayer(player: Person) {
  const variables = {
    id: player.id,
    partition: player.season,
    item: player,
  };
  const response = await callDataApi(gqlUpdatePlayerScore, variables);
  if (response.status === 200) {
    // const j = await response.json();
  }
}
