import { Person } from "../types";
import { PersonBox } from "../components/PersonBox";
import { useCallback, useEffect, useState } from "react";
import {
  getElligiblePlayers,
  loadNextPlayerSet,
  updateScores,
  usePeople,
} from "people";
import { savePlayer } from "dataApi";
import { Button, Center, Flex, Select, Switch, Wrap } from "@chakra-ui/react";

export interface VoteViewProps {}

type VoteMode = "2" | "3";

export function VoteView(props: VoteViewProps) {
  const people = usePeople();
  const [left, setLeft] = useState<Person>();
  const [middle, setMiddle] = useState<Person>();
  const [right, setRight] = useState<Person>();
  const [fire, setFire] = useState<string>("");

  const [showNames, setShowNames] = useState(false);
  const [voteMode, setVoteMode] = useState<VoteMode>("2");

  const nextPair = useCallback(async () => {
    try {
      const elligible = getElligiblePlayers(people);
      if (elligible.length >= 3) {
        let newLeft = Math.floor(Math.random() * elligible.length);
        let newMiddle = Math.floor(Math.random() * elligible.length);
        let newRight = Math.floor(Math.random() * elligible.length);
        while (newLeft === newRight) {
          newRight = Math.floor(Math.random() * elligible.length);
        }
        while (newLeft === newMiddle || newRight === newMiddle) {
          newMiddle = Math.floor(Math.random() * elligible.length);
        }

        const newl = elligible[newLeft];
        const newm = elligible[newMiddle];
        const newr = elligible[newRight];

        const [l, r, m] = await loadNextPlayerSet(newl.id, newr.id, newm.id);

        if (l && r) {
          newl.score = l.score;
          newl.votes = l.votes;
          newr.score = r.score;
          newr.votes = r.votes;
          newm.score = m.score;
          newm.votes = m.votes;
        }

        setFire("");
        setLeft(newl);
        setRight(newr);
        setMiddle(newm);
      }
    } finally {
    }
  }, [people]);

  const vote = useCallback(
    async (winner: Person, l1: Person, l2: Person) => {
      updateScores(winner, l1);
      if (voteMode === "3") {
        updateScores(winner, l2);
      }

      // await createVote(leftWins ? left : right, leftWins ? right : left);
      await savePlayer(winner);
      await savePlayer(l1);

      if (voteMode === "3") {
        await savePlayer(l2);
      }

      nextPair();
    },
    [nextPair, voteMode]
  );

  useEffect(() => {
    if (left && right && middle)
      switch (fire) {
        case "l":
          vote(left, right, middle);
          break;
        case "r":
          vote(right, left, middle);
          break;
        case "m":
          vote(middle, left, right);
          break;
      }
  }, [vote, fire, left, middle, right]);

  const voteSkip = useCallback(async () => {
    nextPair();
  }, [nextPair]);

  useEffect(() => {
    if (!left && !right) {
      nextPair();
    }
  }, [people, nextPair, left, right]);

  return (
    <div>
      <Center>
        <Wrap>
          <PersonBox
            person={left}
            onVote={() => setFire("l")}
            isOnFire={fire === "l"}
            showName={showNames}
          />
          {voteMode === "3" && (
            <PersonBox
              person={middle}
              onVote={() => setFire("m")}
              isOnFire={fire === "m"}
              showName={showNames}
            />
          )}
          <PersonBox
            person={right}
            onVote={() => setFire("r")}
            isOnFire={fire === "r"}
            showName={showNames}
          />
        </Wrap>
      </Center>
      <Center>
        <Button m="2" onClick={voteSkip}>
          Skip
        </Button>
      </Center>
      <Flex>
        <label htmlFor="showNames">Show names</label>
        <Switch
          id="showNames"
          m="2"
          isChecked={showNames}
          onChange={(e) => setShowNames(e.target.checked)}
        />

        <label>Voting mode:</label>
        <Select
          value={voteMode}
          onChange={(e) => setVoteMode(e.target.value as VoteMode)}
        >
          <option value={"2"}>Head-to-head</option>
          <option value={"3"}>Triple</option>
        </Select>
      </Flex>
    </div>
  );
}
