import { useEffect } from "react";
import "./App.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Heading, Tab, TabList, Tabs } from "@chakra-ui/react";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/vote");
    }
  }, [location, navigate]);

  return (
    <div className="App">
      <header>
        <Heading alignSelf={"left"}>HockeyHOT</Heading>
        <Tabs align="end">
          <TabList>
            <Tab>
              <Link to="vote">Vote</Link>
            </Tab>
            <Tab>
              <Link to="rank/players">Player rankings</Link>
            </Tab>
            <Tab>
              <Link to="rank/teams">Team rankings</Link>
            </Tab>
          </TabList>
        </Tabs>
      </header>
      <Outlet />
    </div>
  );
}

export default App;
